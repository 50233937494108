@import "page-builder/config";
@import "page-builder/icons";
@import "page-builder/placeholders";
@import "page-builder/mixins";

@import 'page-builder/layout';
@import 'page-builder/slick/slick';
@import 'page-builder/content-type/import';
@import 'page-builder/jarallax/jarallax';

//
//  Common
//  _____________________________________________


.cms-content-important {
    background-color: $color-white-smoke;
    color: $color-gray20;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

//
//  Styles for legacy content types
//  ---------------------------------------------

.pagebuilder-accordion {
    @include lib-data-accordion();
}


//
//  Mobile (style-m.css)
//  _____________________________________________

@include respond-to-down(md, $breakpoints, true) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

//
//  Desktop (style-l.css)
//  _____________________________________________

@include respond-to-up(lg) {
    .pagebuilder-mobile-only {
        display: none !important;
    }
}
