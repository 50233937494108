// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Full Bleed row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='full-bleed'] {
    box-sizing: border-box;
}

//
//  Mobile (style-m.css)
//  _____________________________________________

@include respond-to-down(md, $breakpoints, true) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important;
    }
}
