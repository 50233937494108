// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Jarallax styles
//  _________________________________________________________________


.jarallax {
    position: relative;
    z-index: 0;
}

.jarallax {
    > .jarallax-img {
        font-family: 'object-fit: cover;', sans-serif;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}

.jarallax-video-fallback {
    transform: scale3d(0, 0, 0);
}

.jarallax {
    .video-overlay {
        -webkit-transform: translate3d(0,0,0);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -99;
    }

    [id*='jarallax-container'] {
        video,
        iframe {
            visibility: hidden;
        }
    }
}

