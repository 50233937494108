// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________


.pagebuilder-column {
    box-sizing: border-box;
}

//
//  Mobile (style-m.css)
//  _____________________________________________

@include respond-to-down(md, $breakpoints, true) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
        max-width: 100%;
    }
}
