@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
div[data-content-type='tabs'] .tab-title:not(span.tab-title) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.pagebuilder-column.image-text-box [data-content-type="text"] a {
  position: relative;
  text-decoration: none;
  background: transparent;
  color: #000;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0 0;
}

.pagebuilder-column.image-text-box [data-content-type="text"] a:focus, .pagebuilder-column.image-text-box [data-content-type="text"] a:hover {
  color: #97999C;
  background: transparent;
}

.pagebuilder-column.image-text-box [data-content-type="text"] a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

@font-face {
  font-family: "ms-icons";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/icons/icons.eot");
  src: url("../../fonts/icons/icons.eot?#iefix") format("embedded-opentype"), url("../../fonts/icons/icons.woff") format("woff"), url("../../fonts/icons/icons.ttf") format("truetype");
}

.pagebuilder-icon {
  -webkit-font-smoothing: antialiased;
  font-family: "ms-icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "ms-icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
}

.pagebuilder-icon-down:before {
  content: "";
}

.pagebuilder-icon-next:before {
  content: "";
}

.pagebuilder-icon-prev:before {
  content: "";
}

.pagebuilder-icon-up:before {
  content: "";
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before, div[data-content-type='tabs'] .tabs-content .tab-title.ui-accordion-header:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ms-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  text-rendering: auto;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.product-full-width-section {
  padding: .5em 0;
}

.product-full-width-section .block.review-add {
  margin-top: 2.7rem;
}

.page-layout-product-full-width .block.related {
  margin-top: 2.7rem;
}

.page-main-details .product-section-title {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 15px;
  padding-bottom: 12px;
}

.additional-attributes-wrapper .additional-attributes {
  border: none;
  width: auto;
}

.additional-attributes-wrapper .additional-attributes > tbody > tr > th {
  border: none;
  padding: 5.5px 30px 10px 0;
}

.additional-attributes-wrapper .additional-attributes > tbody > tr > td {
  border: none;
  padding: 5.5px 5px 10px;
}

.cms-index-index.page-layout-cms-full-width .nav-sections {
  margin-bottom: 0;
}

.slick-slider {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-touch-action: pan-y;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;
  padding: 0;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}

.slick-track:before, .slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  max-width: 100%;
  height: auto;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  position: relative;
}

[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
  color: inherit;
  text-decoration: inherit;
}

[data-content-type='banner'] > [data-element='link']:hover,
[data-content-type='banner'] > [data-element='empty_link']:hover {
  color: inherit;
  text-decoration: inherit;
}

.pagebuilder-banner-wrapper {
  background-clip: padding-box;
  border-radius: inherit;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pagebuilder-banner-wrapper .pagebuilder-overlay {
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  -webkit-transition: background-color 500ms ease;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
  transition: background-color 500ms ease;
}

.pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
}

.pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
  max-width: 540px;
}

.pagebuilder-banner-wrapper.jarallax .video-overlay {
  z-index: 0;
}

.pagebuilder-banner-wrapper [data-element='content'] {
  min-height: 50px;
  overflow: auto;
}

.pagebuilder-banner-wrapper .pagebuilder-banner-button {
  -moz-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  -webkit-transition: opacity 500ms ease;
  margin: 20px 0 0 0;
  max-width: 100%;
  text-align: inherit;
  transition: opacity 500ms ease;
  word-break: break-word;
}

@media screen and (max-width: 767.98px) {
  .pagebuilder-banner-wrapper {
    background-attachment: scroll !important;
  }
  .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
    max-width: none;
  }
}

.pagebuilder-banner-wrapper .pagebuilder-poster-content {
  width: 100%;
}

[data-appearance='collage-centered'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto;
}

[data-appearance='collage-left'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-right: auto;
}

[data-appearance='collage-right'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
}

[data-content-type$='block'] .block p:last-child {
  margin-bottom: 1rem;
  margin-top: 0;
}

[data-content-type='buttons'] {
  max-width: 100%;
}

a.pagebuilder-button-link,
button.pagebuilder-button-link,
div.pagebuilder-button-link, a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
div.pagebuilder-button-primary, a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
div.pagebuilder-button-secondary {
  margin: 0 0 10px 0;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block;
  max-width: none;
  width: 100%;
}

@media screen and (min-width: 430px) {
  a.pagebuilder-button-link,
  button.pagebuilder-button-link,
  div.pagebuilder-button-link, a.pagebuilder-button-primary,
  button.pagebuilder-button-primary,
  div.pagebuilder-button-primary, a.pagebuilder-button-secondary,
  button.pagebuilder-button-secondary,
  div.pagebuilder-button-secondary {
    margin: 0 0 10px 0;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  a.pagebuilder-button-link,
  button.pagebuilder-button-link,
  div.pagebuilder-button-link, a.pagebuilder-button-primary,
  button.pagebuilder-button-primary,
  div.pagebuilder-button-primary, a.pagebuilder-button-secondary,
  button.pagebuilder-button-secondary,
  div.pagebuilder-button-secondary {
    margin: 0 10px 10px 0;
  }
}

a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
div.pagebuilder-button-primary {
  border-radius: 0;
}

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
div.pagebuilder-button-secondary {
  border-radius: 0;
}

[data-content-type='button-item'],
[data-content-type='buttons'] {
  width: 100%;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  [data-content-type='button-item'],
  [data-content-type='buttons'] {
    width: auto;
  }
}

[data-content-type='button-item'].disable-fullwidth,
[data-content-type='buttons'].disable-fullwidth {
  width: 100%;
}

@media screen and (min-width: 430px) {
  [data-content-type='button-item'].disable-fullwidth,
  [data-content-type='buttons'].disable-fullwidth {
    width: auto;
  }
}

[data-content-type=button-item] [data-element=empty_link] {
  cursor: pointer;
}

.pagebuilder-button-primary--small {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pagebuilder-button-primary--large {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pagebuilder-button-secondary--small {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pagebuilder-button-secondary--large {
  padding-top: 18px;
  padding-bottom: 18px;
}

.pagebuilder-column {
  box-sizing: border-box;
}

@media screen and (max-width: 767.98px) {
  .pagebuilder-column {
    background-attachment: scroll !important;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767.98px) {
  .pagebuilder-column-group {
    flex-wrap: wrap;
  }
}

[data-content-type='heading'] {
  word-wrap: break-word;
}

[data-content-type='html'] {
  word-wrap: break-word;
}

figure[data-content-type='image'] {
  box-sizing: border-box;
}

figure[data-content-type='image'] > [data-element='link'],
figure[data-content-type='image'] > [data-element='link'] img {
  border-radius: inherit;
}

figure[data-content-type='image'] figcaption {
  word-wrap: break-word;
}

[data-content-type='map'] {
  box-sizing: border-box;
  height: 300px;
}

[data-content-type='row'] li.product-item {
  text-align: left;
}

.pagebuilder-column .widget-product-grid .product-item {
  max-width: 100%;
  min-width: 50px;
}

.pagebuilder-column .widget-product-grid .product-item .price-box {
  word-wrap: break-word;
}

.pagebuilder-column .widget-product-grid .product-item .price-box .price {
  white-space: normal;
}

.pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-primary {
  display: inline-block;
  max-width: 100%;
}

.pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
  display: inline-block;
  width: auto;
}

.pagebuilder-column .widget-product-grid .product-item .product-item-actions .tocart {
  max-width: 100%;
  white-space: normal;
}

.pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 200px;
}

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item {
  display: block;
}

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item:first-child {
  visibility: visible;
}

[data-content-type='products'][data-appearance='carousel'] .product-item-info {
  width: auto;
}

[data-content-type='products'][data-appearance='carousel'] li.product-item {
  display: none;
}

[data-content-type='products'][data-appearance='carousel'] li.product-item:first-child {
  display: block;
  visibility: hidden;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item {
  opacity: .5;
  transition: all 300ms ease;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item:hover {
  opacity: 1;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .slick-current .product-item {
  opacity: 1;
}

[data-content-type='row'][data-appearance='contained'] {
  box-sizing: border-box;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1280px;
}

[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
  box-sizing: border-box;
}

@media screen and (max-width: 767.98px) {
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    background-attachment: scroll !important;
  }
}

[data-content-type='row'][data-appearance='full-bleed'] {
  box-sizing: border-box;
}

@media screen and (max-width: 767.98px) {
  [data-content-type='row'][data-appearance='full-bleed'] {
    background-attachment: scroll !important;
  }
}

[data-content-type='row'][data-appearance='full-width'] {
  box-sizing: border-box;
}

[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
}

@media screen and (max-width: 767.98px) {
  [data-content-type='row'][data-appearance='full-width'] {
    background-attachment: scroll !important;
  }
}

[data-content-type='slide'] {
  box-sizing: border-box;
  line-height: 20px;
  min-height: inherit;
  overflow: hidden;
}

[data-content-type='slide'] > [data-element='link'],
[data-content-type='slide'] > [data-element='empty_link'] {
  color: inherit;
  min-height: inherit;
  text-decoration: inherit;
}

[data-content-type='slide'] > [data-element='link']:hover,
[data-content-type='slide'] > [data-element='empty_link']:hover {
  color: inherit;
  text-decoration: inherit;
}

[data-content-type='slide'] + [data-content-type='slide'] {
  height: 0;
  min-height: 0;
}

.slick-slider [data-content-type='slide'] + [data-content-type='slide'] {
  height: initial;
  min-height: inherit;
}

.pagebuilder-slide-wrapper {
  border-radius: inherit;
  box-sizing: border-box;
  min-height: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pagebuilder-slide-wrapper .jarallax-viewport-element {
  height: 100%;
  left: -15000vw;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 100;
}

.slick-current .pagebuilder-slide-wrapper .jarallax-viewport-element {
  left: 0;
}

.pagebuilder-slide-wrapper.jarallax .video-overlay {
  -webkit-transform: unset;
  z-index: 1;
}

.pagebuilder-slide-wrapper.jarallax .pagebuilder-overlay {
  position: relative;
  z-index: 2;
}

.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > div,
.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > img,
.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > video,
.pagebuilder-slide-wrapper.jarallax [id*='jarallax-container'] > iframe {
  margin: auto !important;
  transform: none !important;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay {
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  -webkit-transition: background-color 500ms ease;
  border-radius: inherit;
  box-sizing: border-box;
  padding: 30px;
  transition: background-color 500ms ease;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: inherit;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
  max-width: 540px;
}

.pagebuilder-slide-wrapper [data-element='content'] {
  min-height: 50px;
  overflow: auto;
}

.pagebuilder-slide-wrapper .pagebuilder-slide-button {
  -moz-transition: opacity 500ms ease;
  -o-transition: opacity 500ms ease;
  -webkit-transition: opacity 500ms ease;
  margin: 20px 0 0 0;
  max-width: 100%;
  text-align: inherit;
  transition: opacity 500ms ease;
  word-break: break-word;
}

@media screen and (max-width: 767.98px) {
  .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
    max-width: none;
  }
}

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: 100%;
}

[data-appearance='collage-centered'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto;
}

[data-appearance='collage-left'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-right: auto;
}

[data-appearance='collage-right'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
}

div[data-content-type='slider'] {
  visibility: hidden;
}

div[data-content-type='slider'].slick-initialized {
  visibility: visible;
}

div[data-content-type='slider'] .slick-list,
div[data-content-type='slider'] .slick-track,
div[data-content-type='slider'] .slick-slide {
  min-height: inherit;
}

div[data-content-type='slider'] .slick-list > div,
div[data-content-type='slider'] .slick-track > div,
div[data-content-type='slider'] .slick-slide > div {
  line-height: 0;
  min-height: inherit;
  overflow: hidden;
  width: 100%;
}

a.button {
  -moz-appearance: button;
  -webkit-appearance: button;
  appearance: button;
  color: initial;
  padding: 10px;
  text-decoration: none;
}

div[data-content-type='tabs'] .tabs-navigation {
  display: block;
  font-size: 0;
  margin: 0 0 -1px;
  padding: 0;
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header {
  border: 1px solid #fff;
}

@media screen and (min-width: 992px) {
  div[data-content-type='tabs'] .tabs-navigation li.tab-header {
    float: left;
    width: auto;
    margin: 0 2px;
  }
  div[data-content-type='tabs'] .tabs-navigation li.tab-header:first-child {
    margin-left: -1px !important;
  }
}

div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active a.tab-title {
  color: #000;
  background: #fff;
  transition: all .3s;
  border: 1px solid #ccc;
  border-bottom: 0;
  z-index: 19;
  position: relative;
}

div[data-content-type='tabs'] .tab-title:not(span.tab-title) {
  font-size: 1.25rem;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  background: #eee;
  padding: 1.5rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (min-width: 992px) {
  div[data-content-type='tabs'] .tab-title:not(span.tab-title) {
    font-size: 1rem;
    padding: 1rem 2rem;
    border: 1px solid #eee;
  }
  div[data-content-type='tabs'] .tab-title:not(span.tab-title):after {
    display: none;
  }
  div[data-content-type='tabs'] .tab-title:not(span.tab-title):hover {
    text-decoration: none;
  }
}

div[data-content-type='tabs'] .tabs-content {
  padding: 0;
  background: #fff;
}

@media screen and (min-width: 992px) {
  div[data-content-type='tabs'] .tabs-content {
    padding: 3rem;
    float: right;
    margin-left: -100%;
    width: 100%;
    margin-top: 50px;
    border: 1px solid #ccc;
  }
}

div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item'] {
  box-sizing: border-box;
  min-height: inherit;
}

div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item']:not(:first-child) {
  display: none;
}

div[data-content-type='tabs'] .tabs-content .value p:last-of-type {
  margin-bottom: 0;
}

div[data-content-type='tabs'] .tabs-content .tab-title.ui-accordion-header > .ui-icon {
  display: none;
}

div[data-content-type='tabs'] .tabs-content .tab-title.ui-accordion-header:after {
  content: "";
}

div[data-content-type='tabs'] .tabs-content .tab-title.ui-accordion-header-active {
  color: #000;
  background: #fff;
  position: relative;
  transition: all .3s;
}

div[data-content-type='tabs'] .tabs-content .tab-title.ui-accordion-header-active:after {
  content: "";
}

div[data-content-type='tabs'].tab-align-left .tabs-content {
  border-top-left-radius: 0 !important;
}

div[data-content-type='tabs'].tab-align-right .tabs-content {
  border-top-right-radius: 0 !important;
}

div[data-content-type='tabs'] .additional-attributes-wrapper .table-caption {
  display: none;
}

div[data-content-type='tabs'] .additional-attributes th,
div[data-content-type='tabs'] .additional-attributes td {
  padding: 0.25rem;
}

@media screen and (max-width: 768px) {
  [data-content-type='tab-item'] {
    background-attachment: scroll !important;
  }
}

div[data-content-type='text'] {
  word-wrap: break-word;
}

div[data-content-type='video'] {
  font-size: 0;
}

div[data-content-type='video'] .pagebuilder-video-inner {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

div[data-content-type='video'] .pagebuilder-video-container {
  border-radius: inherit;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

div[data-content-type='video'] iframe,
div[data-content-type='video'] video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  font-family: 'object-fit: cover;', sans-serif;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.jarallax-video-fallback {
  transform: scale3d(0, 0, 0);
}

.jarallax .video-overlay {
  -webkit-transform: translate3d(0, 0, 0);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -99;
}

.jarallax [id*='jarallax-container'] video,
.jarallax [id*='jarallax-container'] iframe {
  visibility: hidden;
}

.cms-content-important {
  background-color: #f5f5f5;
  color: "#ccc";
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
  margin: 0 0 35px -20px;
  padding: 20px;
}

.pagebuilder-full-width {
  float: left;
  width: 100%;
}

.pagebuilder-content-type {
  box-sizing: border-box;
  margin-bottom: 20px;
}

.pagebuilder-accordion {
  margin: 0;
  padding: 0;
}

.pagebuilder-accordion > .item.title {
  box-sizing: border-box;
  float: none;
  width: 100%;
}

.pagebuilder-accordion > .item.title > .switch {
  display: block;
}

.pagebuilder-accordion > .item.content {
  box-sizing: border-box;
  display: block;
  float: none;
  margin: 0;
}

.pagebuilder-accordion > .item.content::after {
  display: block;
  clear: both;
  content: "";
}

.pagebuilder-accordion > .item.content.active {
  display: block;
}

.pagebuilder-accordion > .item.title {
  margin-bottom: 4px;
}

.pagebuilder-accordion > .item.title > .switch {
  background-color: #ecebeb;
  color: #000;
  border: 1px solid #cdcccc;
  padding: 20px;
  text-decoration: none;
}

.pagebuilder-accordion > .item.title:not(.disabled) > .switch:focus,
.pagebuilder-accordion > .item.title:not(.disabled) > .switch:hover {
  background-color: #ecebeb;
  color: #000;
}

.pagebuilder-accordion > .item.title:not(.disabled) > .switch:active,
.pagebuilder-accordion > .item.title.active > .switch,
.pagebuilder-accordion > .item.title.active > .switch:focus,
.pagebuilder-accordion > .item.title.active > .switch:hover {
  background: #fff;
  color: #000;
  padding-bottom: 20px;
}

.pagebuilder-accordion > .item.content {
  background: #fff;
  color: #000;
  border: 1px solid #cdcccc;
  margin-bottom: 4px;
  padding: 20px;
}

@media screen and (max-width: 767.98px) {
  .pagebuilder-mobile-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .pagebuilder-mobile-only {
    display: none !important;
  }
}

[data-content-type='row'] li.product-item {
  text-align: left;
}

body .pagebuilder-column .widget-product-grid .product-item {
  max-width: none;
  min-width: 0;
}

body .pagebuilder-column .widget-product-grid .product-item .price-box {
  word-wrap: break-word;
}

body .pagebuilder-column .widget-product-grid .product-item .price-box .price {
  white-space: normal;
}

body .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-primary {
  display: unset;
  max-width: none;
}

body .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
  display: unset;
  width: unset;
}

body .pagebuilder-column .widget-product-grid .product-item .product-item-actions .tocart {
  max-width: none;
  white-space: normal;
}

body .pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 50%;
}

@media screen and (min-width: 992px) {
  body .pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: 33.33333%;
  }
}

@media screen and (min-width: 1200px) {
  body .pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: 25%;
  }
}

/* override the product listing within pagebuilder widgets */
body.cms-page-view:not([class*=pagebuilder]) .column.main .pagebuilder-column [data-content-type=products] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 50%;
  padding: 0 3px 6px;
}

@media screen and (min-width: 430px) {
  body.cms-page-view:not([class*=pagebuilder]) .column.main .pagebuilder-column [data-content-type=products] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    padding: 0 0.5rem 1rem;
  }
}

@media screen and (min-width: 992px) {
  body.cms-page-view:not([class*=pagebuilder]) .column.main .pagebuilder-column [data-content-type=products] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: 33.33333%;
  }
}

@media screen and (min-width: 1200px) {
  body.cms-page-view:not([class*=pagebuilder]) .column.main .pagebuilder-column [data-content-type=products] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: 25%;
  }
}

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item {
  display: block;
}

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item:first-child {
  visibility: visible;
}

[data-content-type='products'][data-appearance='carousel'] .product-item-info {
  width: auto;
}

[data-content-type='products'][data-appearance='carousel'] li.product-item {
  display: none;
}

[data-content-type='products'][data-appearance='carousel'] li.product-item:first-child {
  display: block;
  visibility: hidden;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item {
  opacity: .5;
  transition: all 300ms ease;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item:hover {
  opacity: 1;
}

[data-content-type='products'][data-appearance='carousel'].center-mode .slick-current .product-item {
  opacity: 1;
}

body .section[data-content-type="row"] {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  body .section[data-content-type="row"] {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767.98px) {
  .pagebuilder-mobile-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .pagebuilder-mobile-only {
    display: none !important;
  }
}

[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 1310px;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media screen and (max-width: 991.98px) {
  .pagebuilder-slider {
    display: none;
  }
}

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: auto;
}

body .pagebuilder-slide-wrapper {
  position: relative;
}

.banner-slash[data-appearance="poster"] .pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: auto;
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay,
.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay,
.banner-slash[data-appearance="collage-centered"] .pagebuilder-overlay,
.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay {
  height: 450px;
  background: none !important;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay:before,
.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay:before,
.banner-slash[data-appearance="collage-centered"] .pagebuilder-overlay:before,
.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay .pagebuilder-collage-content,
.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay .pagebuilder-collage-content,
.banner-slash[data-appearance="collage-centered"] .pagebuilder-overlay .pagebuilder-collage-content,
.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay .pagebuilder-collage-content {
  position: relative;
  max-width: 470px;
  margin-top: -30px;
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay {
  justify-content: flex-start;
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay:before {
  left: 0;
  background: linear-gradient(110deg, rgba(0, 0, 0, 0.8) 50%, transparent 0);
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay .pagebuilder-collage-content {
  margin-left: 30px;
}

.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay {
  justify-content: flex-end;
}

.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay:before {
  right: 0;
  background: linear-gradient(-110deg, rgba(0, 0, 0, 0.8) 50%, transparent 0);
}

.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay .pagebuilder-collage-content {
  margin-right: 30px;
}

.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay {
  justify-content: center;
  max-width: 570px;
}

.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay:before {
  display: none;
}

.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay .pagebuilder-collage-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 3rem;
  max-width: 570px;
}

.pagebuilder-slider .slick-arrow.slick-prev:before {
  content: "";
}

.pagebuilder-slider .slick-arrow.slick-next:before {
  content: "";
}

.pagebuilder-slider.pagebuilder-slider-banner-slash .slick-dots {
  position: absolute;
}

.pagebuilder-slider.pagebuilder-slider-banner-slash .slick-dots li button:before {
  background: transparent;
  opacity: 1;
  border: 2px solid white;
  box-shadow: none;
}

.pagebuilder-slider.pagebuilder-slider-banner-slash .slick-dots li.slick-active button:before {
  color: white;
  background: white;
  opacity: 1;
  border: 0;
  box-shadow: none;
}

@media screen and (min-width: 992px) {
  .mobile-only {
    display: none !important;
  }
}

@media screen and (max-width: 991.98px) {
  .block-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  body .text-mobile-only .pagebuilder-collage-content [data-element="content"] > *:not(h1):not(h2):not(h3):not(h4):not(h5) {
    display: none !important;
  }
}

@media screen and (max-width: 991.98px) {
  body .text-desktop-only .pagebuilder-collage-content [data-element="content"] > *:not(h1):not(h2):not(h3):not(h4):not(h5) {
    display: none !important;
  }
}

.color-dark {
  color: #000;
}

.color-light {
  color: #fff;
}

.crop-corner-right-bottom {
  clip-path: polygon(100% 0, 100% 85%, 90% 100%, 0 100%, 0 0);
}

.crop-corner-left-bottom {
  clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 85%, 0 0);
}

.crop-corner-right-top {
  clip-path: polygon(90% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
}

.crop-corner-left-top {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 15%);
}

.crop-corner-block .pagebuilder-banner-wrapper {
  min-height: 0;
  max-height: 137px;
}

@media screen and (min-width: 430px) {
  .crop-corner-block .pagebuilder-banner-wrapper {
    max-height: none;
  }
}

@media screen and (min-width: 768px) {
  .crop-corner-block .pagebuilder-banner-wrapper {
    min-height: 254px;
  }
}

@media screen and (min-width: 992px) {
  .crop-corner-block .pagebuilder-banner-wrapper {
    min-height: 430px;
  }
}

.crop-corner-block p {
  font-size: 20px;
  line-height: 24px;
}

@media screen and (max-width: 429.98px) {
  .crop-corner-block .pagebuilder-overlay {
    padding: 0;
  }
  .crop-corner-block .pagebuilder-overlay .pagebuilder-banner-button {
    margin-top: 0;
    font-size: 10px;
    padding: 3px 18px;
    width: auto;
  }
  .crop-corner-block .pagebuilder-overlay h1,
  .crop-corner-block .pagebuilder-overlay h2,
  .crop-corner-block .pagebuilder-overlay h3,
  .crop-corner-block .pagebuilder-overlay h4,
  .crop-corner-block .pagebuilder-overlay h5 {
    font-size: 20px;
  }
}

.crop-corner-block .pagebuilder-overlay [data-element="content"] {
  font-weight: bold;
}

.crop-corner-block .pagebuilder-overlay [data-element="content"] ul {
  list-style: none;
}

.crop-corner-block .pagebuilder-overlay [data-element="content"] ul > li:before {
  content: "\2014";
  display: inline-block;
  margin-right: 6px;
}

[data-content-type=column] {
  padding: .5rem 0;
}

@media screen and (min-width: 768px) {
  [data-content-type=column] {
    padding: .5rem;
  }
}

.pagebuilder-slide-wrapper [data-element=content] > *:not(h1):not(h2):not(h3):not(h4):not(h5) {
  font-size: 20px;
  line-height: normal;
}

/* equipment hire section */
.pagebuilder-column.image-text-box {
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .pagebuilder-column.image-text-box {
    padding-bottom: 40px;
  }
}

.pagebuilder-column.image-text-box .linked-image {
  position: relative;
}

.pagebuilder-column.image-text-box .linked-image:before {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #b0aa00;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  touch-action: none;
  pointer-events: none;
}

.pagebuilder-column.image-text-box .linked-image > a {
  display: block;
}

.pagebuilder-column.image-text-box.left-border .linked-image:before {
  left: 0;
}

.pagebuilder-column.image-text-box.right-border .linked-image:before {
  right: 0;
}

.pagebuilder-column.image-text-box.right-border:hover .linked-image:before, .pagebuilder-column.image-text-box.left-border:hover .linked-image:before {
  width: 100%;
}

.pagebuilder-column.image-text-box.top-border .linked-image:before {
  top: 0;
  left: 0;
  height: 10px;
  width: 100%;
}

.pagebuilder-column.image-text-box.bottom-border .linked-image:before {
  top: auto;
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%;
}

.pagebuilder-column.image-text-box.top-border:hover .linked-image:before, .pagebuilder-column.image-text-box.bottom-border:hover .linked-image:before {
  height: 100%;
}

.pagebuilder-column.image-text-box [data-content-type="text"] {
  display: block;
  padding: 2rem;
  text-align: left;
  background-color: #fff;
}

.pagebuilder-column.image-text-box [data-content-type="text"] a {
  display: block;
}

.pagebuilder-column.image-text-box [data-content-type="text"] a:hover {
  color: #b0aa00;
}

.pagebuilder-column.image-text-box [data-content-type="text"] p {
  margin: 0;
}

.pagebuilder-column.image-text-box [data-content-type="text"] > * {
  font-family: "Univers Condensed Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.02em;
  font-size: 26px;
}

@media screen and (min-width: 992px) {
  .pagebuilder-column.image-text-box [data-content-type="text"] > * {
    font-size: 36px;
  }
}

.pagebuilder-column.image-text-box:hover .linked-image:before {
  opacity: .5;
  transition: all 0.3s ease-in-out;
}

.pagebuilder-column.image-text-box:hover [data-content-type="text"] a {
  color: #b0aa00;
}

/* override the font size for title section area */
.section-title[data-content-type=heading],
.section-title-secondary[data-content-type=text] {
  max-width: 1040px;
}

.section-title-secondary[data-content-type=text] {
  font-size: 16px;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (min-width: 992px) {
  .section-title-secondary[data-content-type=text] {
    font-size: 20px;
  }
}

/* sector article styles */
@media screen and (max-width: 767.98px) {
  .sector-article {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.sector-article [data-content-type=text] {
  margin-top: 20px;
}

.sector-article [data-content-type=text] h1,
.sector-article [data-content-type=text] h2,
.sector-article [data-content-type=text] h3,
.sector-article [data-content-type=text] h4,
.sector-article [data-content-type=text] h5,
.sector-article [data-content-type=text] h6 {
  font-family: "Univers Condensed Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.sector-article [data-content-type=text] h5 {
  font-size: 25px;
}

@media screen and (min-width: 992px) {
  .sector-article [data-content-type=text] h5 {
    font-size: 31px;
  }
}

/* featured news section */
body .featured-news,
body[class^="amblog-index-"] {
  /* set height for images based on 16:9 dimensions for grid list view only */
  /* set height on titles only on grid list view */
  /* reduce on hover amount for listing page list view */
  /* remove tags on both grid and list view */
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-wrapper,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-wrapper {
  position: relative;
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-image,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-wrapper .amblog-image,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-image,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-wrapper .amblog-image {
  border-radius: 0;
  width: 100%;
  height: auto;
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-date,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-wrapper .amblog-date,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-date,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-wrapper .amblog-date {
  display: inline-block;
  width: auto;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-size: 16px;
  color: #000;
  background-color: #b0aa00;
}

@media screen and (min-width: 992px) {
  body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper,
  body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper {
    height: 0;
    padding-bottom: 56.25%;
  }
}

@media screen and (min-width: 992px) {
  body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-image,
  body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-content .amblog-shot,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-content .amblog-shot,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-content .amblog-shot,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-content .amblog-shot {
  display: block;
}

body .featured-news .amblog-content,
body[class^="amblog-index-"] .amblog-content {
  text-align: left;
}

body .featured-news .amblog-container-list > .amblog-post-container .amblog-content,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-footer,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-content,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-footer {
  padding-left: 0;
  padding-right: 0;
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-read,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-read,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-read,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-read {
  margin-top: 0;
}

body .featured-news .amblog-grid-list .amblog-title,
body .featured-news .amblog-container-list .amblog-title,
body[class^="amblog-index-"] .amblog-grid-list .amblog-title,
body[class^="amblog-index-"] .amblog-container-list .amblog-title {
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: normal;
  text-transform: none;
  position: relative;
}

body .featured-news .amblog-grid-list .amblog-title .amblog-grid-list .amblog-link,
body .featured-news .amblog-container-list .amblog-title .amblog-grid-list .amblog-link,
body[class^="amblog-index-"] .amblog-grid-list .amblog-title .amblog-grid-list .amblog-link,
body[class^="amblog-index-"] .amblog-container-list .amblog-title .amblog-grid-list .amblog-link {
  color: #000;
  text-transform: none;
}

@media screen and (min-width: 992px) {
  body .featured-news .amblog-grid-list .amblog-title,
  body[class^="amblog-index-"] .amblog-grid-list .amblog-title {
    height: 105px;
    overflow: hidden;
  }
  body .featured-news .amblog-grid-list .amblog-title:after,
  body[class^="amblog-index-"] .amblog-grid-list .amblog-title:after {
    display: block;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    content: "";
    background: white;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, white 100%);
  }
}

body .featured-news .amblog-grid-list .amblog-shot,
body .featured-news .amblog-container-list .amblog-shot,
body[class^="amblog-index-"] .amblog-grid-list .amblog-shot,
body[class^="amblog-index-"] .amblog-container-list .amblog-shot {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 992px) {
  body .featured-news .amblog-grid-list .amblog-shot,
  body .featured-news .amblog-container-list .amblog-shot,
  body[class^="amblog-index-"] .amblog-grid-list .amblog-shot,
  body[class^="amblog-index-"] .amblog-container-list .amblog-shot {
    font-size: 20px;
    line-height: 30px;
  }
}

body .featured-news .amblog-grid-list > .amblog-item,
body .featured-news .amblog-container-list > .amblog-post-container,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container {
  background-color: #fff;
  border: 0;
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-wrapper,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-wrapper {
  overflow: hidden;
}

body .featured-news .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-image,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-wrapper .amblog-image,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-wrapper .amblog-image,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-wrapper .amblog-image {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

body .featured-news .amblog-grid-list > .amblog-item:hover .amblog-image,
body .featured-news .amblog-container-list > .amblog-post-container:hover .amblog-image,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item:hover .amblog-image,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container:hover .amblog-image {
  transform: scale(1.2);
  transition: transform 0.5s ease-in-out;
}

body .featured-news .amblog-container-list > .amblog-post-container:hover .amblog-image,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container:hover .amblog-image {
  transform: scale(1.05);
}

body .featured-news .amblog-grid-list > .amblog-item > .amblog-footer .amblog-features,
body .featured-news .amblog-container-list > .amblog-post-container > .amblog-footer .amblog-features,
body .featured-news .amblog-grid-list > .amblog-item .amblog-tags,
body .featured-news .amblog-container-list > .amblog-post-container .amblog-tags,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item > .amblog-footer .amblog-features,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container > .amblog-footer .amblog-features,
body[class^="amblog-index-"] .amblog-grid-list > .amblog-item .amblog-tags,
body[class^="amblog-index-"] .amblog-container-list > .amblog-post-container .amblog-tags {
  display: none;
}

/* remove set height on titles for homepage featured news block */
body .featured-news .amblog-grid-list .amblog-title {
  height: auto;
  overflow: unset;
}

body .featured-news .amblog-grid-list .amblog-title:after {
  display: none;
}

/* remove due to border boxes selector having underline on cms pages */
body.cms-page-view:not([class*=pagebuilder]) .column.main p > a {
  text-decoration: none;
}

/* spacing button CTA by default on sections */
.section[data-content-type=row] [data-content-type=buttons] {
  padding-top: 20px;
}

@media screen and (min-width: 992px) {
  .section[data-content-type=row] [data-content-type=buttons] {
    padding-top: 70px;
  }
}

/* button on mobiles auto width and left aligned */
@media screen and (max-width: 767.98px) {
  body [data-content-type=button-item],
  body [data-content-type=buttons] {
    width: auto;
    text-align: left !important;
  }
}

/* blog listing page */
body .amblog-recent-post {
  display: block;
}

body .amblog-recent-post .amblog-recent-post-image-wrapper {
  margin-right: 0;
  margin-bottom: 1rem;
}
