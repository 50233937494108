// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Mobile (style-m.css)
//  _____________________________________________

@include respond-to-down(md, $breakpoints, true) {
    .pagebuilder-column-group {
        flex-wrap: wrap;
    }
}
