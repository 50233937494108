// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Contained row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: $layout__max-width;

    [data-element='inner'] {
        box-sizing: border-box;
    }
}


//
//  Mobile (style-m.css)
//  _____________________________________________

@include respond-to-down(md, $breakpoints, true) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important;
        }
    }
}
