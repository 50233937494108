// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________

%button-standard {
    margin: 0 0 10px 0;
    box-shadow: none;
    box-sizing: border-box;
    display: inline-block;
    max-width: none;
    width: 100%;

    @include respond-to-up(smm) {
        margin: 0 0 10px 0;
        width: auto;
    }

    @include respond-to-up(md) {
        margin: 0 10px 10px 0;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @extend %button-standard;
    }

    &.pagebuilder-button-primary {
        @extend %button-standard;
        border-radius: $button-border-radius;
    }

    &.pagebuilder-button-secondary {
        @extend %button-standard;
        border-radius: $button-border-radius;
    }
}

[data-content-type='button-item'],
[data-content-type='buttons'] {
    width: 100%;
    cursor: pointer;

    @include respond-to-up(md) {
        width: auto;
    }

    &.disable-fullwidth {
        width: 100%;

        @include respond-to-up(smm) {
            width: auto;
        }
    }
}

[data-content-type=button-item] [data-element=empty_link] {
    cursor: pointer;
}

.pagebuilder-button-primary {
    &--small {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &--large {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.pagebuilder-button-secondary {
    &--small {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &--large {
        padding-top: 18px;
        padding-bottom: 18px;
    }
}
