// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________
div[data-content-type='tabs'] {

    .tabs-navigation {
        display: block;
        font-size: 0;
        margin: 0 0 -1px;
        padding: 0;

        li.tab-header {
            border: 1px solid $white;

            @include respond-to-up($product-tab-breakpoint) {
                float: left;
                width: auto;
                margin: 0 2px;

                &:first-child {
                    margin-left: -1px !important;
                }
            }

            &.ui-state-active a.tab-title {
                color: $product-tab-item-active-color;
                background: $product-tab-item-active-bg;
                transition: all .3s;
                border: 1px solid $color-gray80;
                border-bottom: 0;
                z-index: 19;
                position: relative;
            }
        }
    }

    .tab-title:not(span.tab-title) {
        @extend %u-flex-row-xcenter-ycenter;
        font-size: 1.25rem;
        justify-content: space-between;
        color: $product-tab-item-color;
        background: $product-tab-item-bg;
        padding: $product-tab-item-padding-mobile;
        font-weight: $product-tab-item-font-weight;
        line-height: 1.1;

        @include respond-to-up($product-tab-breakpoint) {
            font-size: 1rem;
            padding: $product-tab-item-padding-desktop $product-tab-item-padding-desktop * 2;
            border: 1px solid $product-tab-item-bg;

            &:after {
                display: none;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .tabs-content {
        padding: 0;
        background: $product-tab-item-active-bg;

        @include respond-to-up($product-tab-breakpoint) {
            padding: spacers(5);
            float: right;
            margin-left: -100%;
            width: 100%;
            margin-top: $product-tab-item-height - 4px;
            border: 1px solid $color-gray80;
        }

        [data-content-type='tab-item'] {
            box-sizing: border-box;
            min-height: inherit;

            &:not(:first-child) {
                display: none;
            }
        }

        .value p:last-of-type {
            margin-bottom: 0;
        }

        .tab-title.ui-accordion-header {
            > .ui-icon {
                display: none;
            }

            &:after {
                @extend %pagebuilder-icon;
                content: $icon-pagebuilder__plus;
            }
        }

        .tab-title.ui-accordion-header-active {
            color: $product-tab-item-active-color;
            background: $product-tab-item-active-bg;
            position: relative;
            transition: all .3s;

            &:after {
                content: $icon-pagebuilder__minus;
            }
        }
    }

    &.tab-align-left {
        .tabs-content {
            border-top-left-radius: 0 !important;
        }
    }
    &.tab-align-right {
        .tabs-content {
            border-top-right-radius: 0 !important;
        }
    }

    .additional-attributes-wrapper .table-caption {
        display: none;
    }

    .additional-attributes {
        th,
        td {
            padding: spacers(1);
        }
    }
}

//
//  Mobile (style-m.css)
//  _____________________________________________

@include respond-to-down(md) {
    [data-content-type='tab-item'] {
        background-attachment: scroll !important;
    }
}
