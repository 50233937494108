// variable overrides here
$layout__max-width: 1310px;

body .section[data-content-type="row"] {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;

    @include respond-to-up($tweakpoint-nav-toggle) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.pagebuilder-mobile-hidden {
    @include respond-to-down(md, $breakpoints, true) {
        display: none !important;
    }
}

.pagebuilder-mobile-only {
    @include respond-to-up(md) {
        display: none !important;
    }
}

[data-content-type=row][data-appearance=contained] {
    //max-width: $layout__max-width;
    //padding-left: 1rem;
    //padding-right: 1rem;
}

[data-content-type='row'][data-appearance='full-width'] {
    > .row-full-width-inner {
        max-width: $layout__max-width;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
.pagebuilder-slider {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        display: none;
    }
}

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
    width: auto;
}

body .pagebuilder-slide-wrapper {
    position: relative;
}

.banner-slash[data-appearance="poster"] .pagebuilder-slide-wrapper .pagebuilder-poster-content {
    width: auto;
}

.banner-slash[data-appearance="collage-left"],
.banner-slash[data-appearance="collage-right"],
.banner-slash[data-appearance="collage-centered"],
.pagebuilder-slider [data-appearance="collage-centered"] {
    .pagebuilder-overlay {
        //height: 805px;
        height: 450px;
        background: none !important;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .pagebuilder-collage-content {
            position: relative;
            max-width: 470px;
            margin-top: -30px;
        }
    }
}

.banner-slash[data-appearance="collage-left"] .pagebuilder-overlay {
    justify-content: flex-start;

    &:before {
        left: 0;
        background: linear-gradient(110deg, rgba(0, 0, 0, .8) 50%, transparent 0);
    }

    .pagebuilder-collage-content {
        margin-left: 30px;
    }
}

.banner-slash[data-appearance="collage-right"] .pagebuilder-overlay {
    justify-content: flex-end;

    &:before {
        right: 0;
        background: linear-gradient(-110deg, rgba(0, 0, 0, .8) 50%, transparent 0);
    }

    .pagebuilder-collage-content {
        margin-right: 30px;
    }
}

.pagebuilder-slider [data-appearance="collage-centered"] .pagebuilder-overlay {
    justify-content: center;
    max-width: 570px;

    &:before {
        display: none;
    }

    .pagebuilder-collage-content {
        background: rgba(0, 0, 0, .8);
        padding: 3rem;
        max-width: 570px;
    }
}

.pagebuilder-slider .slick-arrow {
    &.slick-prev {
        &:before {
            content: map-get($ms-icons, 'arrow-left');
        }
    }

    &.slick-next {
        &:before {
            content: map-get($ms-icons, 'arrow-right');
        }
    }
}

.pagebuilder-slider.pagebuilder-slider-banner-slash .slick-dots {
    position: absolute;

    li button:before {
        background: transparent;
        opacity: 1;
        border: 2px solid white;
        box-shadow: none;
    }

    li.slick-active button:before {
        color: white;
        background: white;
        opacity: 1;
        border: 0;
        box-shadow: none;
    }
}

.mobile-only {
    @include respond-to-up($tweakpoint-nav-toggle) {
        display: none !important;
    }
}

.block-desktop-only {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        display: none !important;
    }
}

body .text-mobile-only .pagebuilder-collage-content [data-element="content"] > *:not(h1):not(h2):not(h3):not(h4):not(h5) {
    @include respond-to-up($tweakpoint-nav-toggle) {
        display: none !important;
    }
}

body .text-desktop-only .pagebuilder-collage-content [data-element="content"] > *:not(h1):not(h2):not(h3):not(h4):not(h5) {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        display: none !important;
    }
}

.color-dark {
    color: $black;
}

.color-light {
    color: $white;
}

.crop-corner-right-bottom {
    clip-path: polygon(100% 0, 100% 85%, 90% 100%, 0 100%, 0 0);
}

.crop-corner-left-bottom {
    clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 85%, 0 0);
}

.crop-corner-right-top {
    clip-path: polygon(90% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
}

.crop-corner-left-top {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 15%);
}

.crop-corner-block {
    .pagebuilder-banner-wrapper {
        min-height: 0;
        max-height: 137px;

        @include respond-to-up(smm) {
            max-height: none;
        }

        @include respond-to-up(md) {
            min-height: 254px;
        }

        @include respond-to-up($tweakpoint-nav-toggle) {
            min-height: 430px;
        }
    }

    p {
        font-size: 20px;
        line-height: 24px;
    }

    .pagebuilder-overlay {
        @include respond-to-down(smm, $breakpoints, true) {
            padding: 0;

            .pagebuilder-banner-button {
                margin-top: 0;
                font-size: 10px;
                padding: 3px 18px;
                width: auto;
            }

            h1,
            h2,
            h3,
            h4,
            h5 {
                font-size: 20px;
            }
        }

        [data-element="content"] {
            font-weight: bold;

            ul {
                list-style: none;

                > li {

                    &:before {
                        content: "\2014";
                        display: inline-block;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}

[data-content-type=row][data-appearance=contained] {
    //max-width: 1310px;
    //padding: 0;
}

[data-content-type=column] {
    padding: .5rem 0;

    @include respond-to-up(md) {
        padding: .5rem;
    }
}

.pagebuilder-slide-wrapper [data-element=content] > *:not(h1):not(h2):not(h3):not(h4):not(h5) {
    font-size: 20px;
    line-height: normal;
}

/* equipment hire section */
.pagebuilder-column.image-text-box {
    padding-bottom: 20px;

    @include respond-to-up($tweakpoint-nav-toggle) {
        padding-bottom: 40px;
    }

    .linked-image {
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: map-get($theme-colors, 'primary');
            opacity: 1;
            transition: all 0.3s ease-in-out;
            touch-action: none;
            pointer-events: none;
        }

        > a {
            display: block;
        }
    }

    &.left-border {
        .linked-image:before {
            left: 0;
        }
    }

    &.right-border {
        .linked-image:before {
            right: 0;
        }
    }

    &.right-border,
    &.left-border {
        &:hover {
            .linked-image:before {
                width: 100%;
            }
        }
    }

    &.top-border {
        .linked-image:before {
            top: 0;
            left: 0;
            height: 10px;
            width: 100%;
        }
    }

    &.bottom-border {
        .linked-image:before {
            top: auto;
            bottom: 0;
            left: 0;
            height: 10px;
            width: 100%;
        }
    }

    &.top-border,
    &.bottom-border {
        &:hover {
            .linked-image:before {
                height: 100%;
            }
        }
    }

    [data-content-type="text"] {
        display: block;
        padding: 2rem;
        text-align: left;
        background-color: $white;

        a {
            @extend %button-whistle;
            display: block;

            &:hover {
                color: map-get($theme-colors, 'primary');
            }
        }

        p {
            margin: 0;
        }

        > * {
            font-family: $font-family-condensed;
            letter-spacing: -0.02em;
            font-size: 26px;

            @include respond-to-up($tweakpoint-nav-toggle) {
                font-size: 36px;
            }
        }
    }

    &:hover {
        .linked-image:before {
            opacity: .5;
            transition: all 0.3s ease-in-out;
        }
        [data-content-type="text"] a {
            color: map-get($theme-colors, 'primary');
        }
    }
}

/* override the font size for title section area */
.section-title[data-content-type=heading],
.section-title-secondary[data-content-type=text] {
    max-width: 1040px;
}
.section-title-secondary[data-content-type=text] {
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 20px;
    }
}

/* sector article styles */
.sector-article {
    @include respond-to-down(md, $breakpoints, true) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    [data-content-type=text] {
        margin-top: 20px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font-family-condensed;
        }

        h5 {
            font-size: 25px;

            @include respond-to-up($tweakpoint-nav-toggle) {
                font-size: 31px;
            }
        }
    }
}

/* featured news section */
body .featured-news,
body[class^="amblog-index-"] {
    .amblog-grid-list>.amblog-item .amblog-wrapper,
    .amblog-container-list>.amblog-post-container .amblog-wrapper {
        position: relative;

        .amblog-image {
            border-radius: 0;
            width: 100%;
            height: auto;
        }

        .amblog-date {
            display: inline-block;
            width: auto;
            height: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1rem;
            font-size: 16px;
            color: $black;
            background-color: map-get($theme-colors, 'primary');
        }
    }

    /* set height for images based on 16:9 dimensions for grid list view only */
    .amblog-grid-list>.amblog-item .amblog-wrapper {
        @include respond-to-up($tweakpoint-nav-toggle) {
            height: 0;
            padding-bottom: 56.25%;
        }

        .amblog-image {
            @include respond-to-up($tweakpoint-nav-toggle) {
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }
    }

    .amblog-grid-list>.amblog-item .amblog-content,
    .amblog-container-list>.amblog-post-container .amblog-content {
        .amblog-shot {
            display: block;
        }
    }

    .amblog-content {
        text-align: left;
    }

    .amblog-container-list>.amblog-post-container .amblog-content,
    .amblog-container-list>.amblog-post-container .amblog-footer {
        padding-left: 0;
        padding-right: 0;
    }

    .amblog-grid-list>.amblog-item .amblog-read,
    .amblog-container-list>.amblog-post-container .amblog-read {
        margin-top: 0;
    }

    .amblog-grid-list .amblog-title,
    .amblog-container-list .amblog-title {
        font-family: $font-family-base;
        font-weight: 700;
        line-height: normal;
        text-transform: none;
        position: relative;

        .amblog-grid-list .amblog-link {
            color: $black;
            text-transform: none;
        }
    }

    /* set height on titles only on grid list view */
    .amblog-grid-list .amblog-title {
        @include respond-to-up($tweakpoint-nav-toggle) {
            height: 105px;
            overflow: hidden;

            &:after {
                display: block;
                pointer-events: none;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40%;
                content: "";
                background: rgb(255,255,255);
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
            }
        }
    }

    .amblog-grid-list .amblog-shot,
    .amblog-container-list .amblog-shot {
        font-size: 16px;
        line-height: 24px;

        @include respond-to-up($tweakpoint-nav-toggle) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .amblog-grid-list>.amblog-item,
    .amblog-container-list>.amblog-post-container {
        background-color: $white;
        border: 0;

        .amblog-wrapper {
            overflow: hidden;

            .amblog-image {
                transform: scale(1);
                transition: transform 0.5s ease-in-out;
            }
        }

        &:hover {
            .amblog-image {
                transform: scale(1.2);
                transition: transform 0.5s ease-in-out;
            }
        }
    }

    /* reduce on hover amount for listing page list view */
    .amblog-container-list>.amblog-post-container:hover .amblog-image {
        transform: scale(1.05);
    }

    /* remove tags on both grid and list view */
    .amblog-grid-list>.amblog-item>.amblog-footer .amblog-features,
    .amblog-container-list>.amblog-post-container>.amblog-footer .amblog-features,
    .amblog-grid-list>.amblog-item .amblog-tags,
    .amblog-container-list>.amblog-post-container .amblog-tags {
        display: none;
    }
}

/* remove set height on titles for homepage featured news block */
body .featured-news .amblog-grid-list .amblog-title {
    height: auto;
    overflow: unset;

    &:after {
        display: none;
    }
}

/* remove due to border boxes selector having underline on cms pages */
body.cms-page-view:not([class*=pagebuilder]) .column.main p>a {
    text-decoration: none;
}

/* spacing button CTA by default on sections */
.section[data-content-type=row] {
    [data-content-type=buttons] {
        padding-top: 20px;

        @include respond-to-up($tweakpoint-nav-toggle) {
            padding-top: 70px;
        }
    }
}

/* button on mobiles auto width and left aligned */
body [data-content-type=button-item],
body [data-content-type=buttons] {
    @include respond-to-down(md, $breakpoints, true) {
        width: auto;
        text-align: left !important;
    }
}

/* blog listing page */
body .amblog-recent-post {
    display: block;

    .amblog-recent-post-image-wrapper {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}
