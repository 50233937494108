//
//  Common
//  _____________________________________________

@font-face {
    font-family: $icons-pagebuilder__font-name;
    font-style: normal;
    font-weight: normal;
    src: url('#{$icons-pagebuilder__font-path}.eot');
    src: url('#{$icons-pagebuilder__font-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$icons-pagebuilder__font-path}.woff') format('woff'),
    url('#{$icons-pagebuilder__font-path}.ttf') format('truetype');
}

.pagebuilder-icon {
    -webkit-font-smoothing: antialiased;
    font-family: $icons-pagebuilder__font-name;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: $icons-pagebuilder__font-name;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
}

.pagebuilder-icon-down:before {
    content: map-get($ms-icons, 'chevron-down');
}

.pagebuilder-icon-next:before {
    content: map-get($ms-icons, 'chevron-right');
}

.pagebuilder-icon-prev:before {
    content: map-get($ms-icons, 'chevron-left');
}

.pagebuilder-icon-up:before {
    content: map-get($ms-icons, 'chevron-up');
}
