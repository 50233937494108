// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Default appearance styles
//  _____________________________________________


[data-content-type='row'] {
    li.product-item {
        text-align: left;
    }
}

body .pagebuilder-column {
    .widget-product-grid {
        .product-item {
            max-width: none;
            min-width: 0;

            .price-box {
                word-wrap: break-word;

                .price {
                    white-space: normal;
                }
            }

            .product-item-actions {
                .actions-primary {
                    display: unset;
                    max-width: none;
                }

                .actions-secondary {
                    display: unset;
                    width: unset;
                }

                .tocart {
                    max-width: none;
                    white-space: normal;
                }
            }
        }
    }

    [data-content-type='products'] {
        .block.widget {
            .products-grid {
                .widget-product-grid.product-items {
                    .product-item {
                        width: 50%;

                        @include respond-to-up($tweakpoint-nav-toggle) {
                            width: 33.33333%;
                        }

                        @include respond-to-up(xl) {
                            width: 25%;
                        }
                    }
                }
            }
        }
    }
}

/* override the product listing within pagebuilder widgets */
body.cms-page-view:not([class*=pagebuilder]) .column.main .pagebuilder-column [data-content-type=products] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: 50%;
    padding: 0 3px 6px;

    @include respond-to-up(smm) {
        padding: 0 0.5rem 1rem;
    }

    @include respond-to-up($tweakpoint-nav-toggle) {
        width: 33.33333%;
    }

    @include respond-to-up(xl) {
        width: 25%;
    }
}

